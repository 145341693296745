import React from "react"
// import { Link } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
import typography from "../utils/typography"
import SEO from "../components/seo"
import { Navigation } from "../components/navbar"

const IndexPage = () => (
  <>
    <Navigation></Navigation>
    <SEO title="Markiyan Melnychenko | Violinist" />
    <header
      class="masthead"
      style={{
        height: `100vh`,
        minHeight: `500px`,
        backgroundSize: `cover`,
        backgroundPosition: `center 15%`,
        backgroundRepeat: `no-repeat`,
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/headerImageMarkiyan.jpg")`,
      }}
    >
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          alignItems: `center`,
          height: `100%`,
          textAlign: `center`,
          color: `white`,
        }}
      >
        <div class="row h-100 align-items-center">
          <div class="col-12 text-center">
            <h1 style={{ color: `white`, fontWeight: `500` }}>
              Markiyan Melnychenko CF
            </h1>
            <h3 style={{ color: `white` }}>Violinist</h3>
          </div>
        </div>
      </div>
      <style>{typography.toString()}</style>
    </header>
    <footer
        style={{
          background: `rgb(20, 19, 19)`,
          color: `white`,
          width: `100%`,
          textAlign: `center`,
          padding: `7rem 0`,
        }}
      >
        <h3 style={{ color: `white` }}>Represented by <a href="http://www.weaverartistmanagement.com.au/">Weaver Artist Management</a></h3>
        <h4 style={{ color: `white` }}>
          Copyright © 2019 - {new Date().getFullYear()} Markiyan Melnychenko | All rights reserved
        </h4>
      </footer>
  </>
)

export default IndexPage
